<template>
  <div>
    <b-modal
        class="created-users-modal"
        ref="affiliateModal"
        :hide-footer="true"
        :hide-header="true"
        v-model="showModal"
        size="lg">
      <created-accounts-modal :id="userId" @closeModal="showModal = false"></created-accounts-modal>
    </b-modal>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row>
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..."/>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
          ref="refTable"
          :items="fetchAffiliateUsers"
          responsive
          :fields="affiliateTable"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc">
        <template #cell(user)="data">
          <div class="d-flex">
            <b-media vertical-align="center" class="align-items-center">
              <template #aside class="d-flex align-items-center">
                <b-avatar
                    size="32"
                    :src="data.item.profileImage"
                    :text="avatarText(data.item.fullName)"
                />
              </template>
              <b-link
                  target="_blank"
                  class="font-weight-bold d-block text-nowrap"
                  :to="resolveUserLink(data.item.type, data.item.uid)">
                {{ data.item.name }}
              </b-link>
            </b-media>
          </div>
        </template>

        <template #cell(accountsNumber)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.conversionTotalCount }}</span>
            <b-button @click="openModal(data.item.uid)" variant="success" class="ml-1 p-1" size="sm">View accounts
            </b-button>
          </div>
        </template>

        <template #cell(createdAccounts)="data">
          <span class="align-text-top text-capitalize">{{ data.item.conversionCreatedCount }}</span>
        </template>

        <template #cell(verifiedAccounts)="data">
          <span class="align-text-top text-capitalize">{{ data.item.conversionVerifiedCount }}</span>
        </template>

        <template #cell(confirmedAccounts)="data">
          <span class="align-text-top text-capitalize">{{ data.item.conversionConfirmedCount }}</span>
        </template>

        <template #cell(verifiedAccounts)="data">
          <span class="align-text-top text-capitalize">{{ data.item.conversionVerifiedCount }}</span>
        </template>

        <template #cell(invalidAccounts)="data">
          <span class="align-text-top text-capitalize">{{ data.item.conversionInvalidCount }}</span>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination
                v-model="currentPage"
                :total-rows="totalCount"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18"/>
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18"/>
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import {avatarText} from '@core/utils/filter'
import useUsersList from './useUsersList'
import UsersFilters from '@/components/users/UsersFilters'
import CreatedAccountsModal from "@/views/Users/CreatedAccountsModal";

export default {
  components: {
    CreatedAccountsModal,
    UsersFilters,
    vSelect,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
  },
  data() {
    return {
      showPremiumModal: false,
      companyPremiumUid: null,
      companyPremiumFrom: null,
      companyPremiumTo: null,
      showModal: false,
      userId: ''
    }
  },
  methods: {
    deleteProfile(uid) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteUser(uid)
          this.$swal({
            icon: 'success',
            title: 'Deleted!',
            text: 'User has been deleted.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    async loginAsUser(uid) {
      const {token} = await this.$httpService.auth.getUserToken(uid)
      const url = process.env.VUE_APP_REDIRECT_LINK + '/proxy-auth?token=' + token
      window.open(url, '_blank')
    },
    openCompanyPremiumModal(uid, from, to) {
      this.companyPremiumUid = uid
      this.companyPremiumFrom = from
      this.companyPremiumTo = to
      this.showPremiumModal = true
    },
    onCompanyPremiumChanged() {
      this.showPremiumModal = false
      this.refetchData()
    },
    openModal(uid) {
      this.userId = uid;
      this.showModal = true;
    }
  },
  setup() {
    const userRole = localStorage.getItem('role')
    const usersList = useUsersList(false, false)
    return {
      avatarText,
      userRole,
      ...usersList,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.options-dropdown {
  position: static;
}

</style>

